import { useTranslation } from "react-i18next";
import clock from "../../assets/img/icon/clock.svg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
const Program = ({ data,type  }) => {
  // const handleDownloadPdf = () => {
  //   // Geçici bir div oluşturup, içeriği bu div'e ekle
  //   const div = document.createElement("div");
  //   div.innerHTML = data.description;
  //   div.style.padding = "20px";
  //   document.body.appendChild(div);

  //   html2canvas(div, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "pt", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = pdfWidth - 40; // 20px sağ ve sol boşluk
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //     pdf.addImage(imgData, "PNG", 20, 20, imgWidth, imgHeight);
  //     pdf.save("download.pdf");
  //     document.body.removeChild(div);
  //   });
  // };
  // Local State
  const [handleCountDatas, setHandleCountDatas] = useState(4);
  const { t } = useTranslation();

  // Location
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setHandleCountDatas(4);
    }
  }, [location]);

  const handleDownload = async () => {
    try {
      // Dosyayı indir
      const response = await fetch(
        `https://www.uimconsulting.com/uploads/courses/files/${data.programfile}`
      );

      // Yanıtı Blob olarak al
      const blob = await response.blob();

      // Blob'u URL'e dönüştür
      const url = window.URL.createObjectURL(blob);

      // Bir <a> etiketi oluştur
      const a = document.createElement("a");
      a.href = url;
      a.download = "document.pdf"; // İndirilecek dosyanın adı

      // <a> etiketini tıklat
      document.body.appendChild(a);
      a.click();

      // <a> etiketini temizle
      document.body.removeChild(a);

      // Kullanılmayan URL'yi temizle
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Dosya indirilemedi:", error);
    }
  };
  return (
    <section className="detail-diarly ">
      {data.diary.length !== 0 && (
        <div className="container">
          <div className="caption">
            <h6 className="title">
              {t("detail_general_info.program_caption")}
            </h6>
            {data.programfile && (
              <Link
                to={`https://www.uimconsulting.com/uploads/${type}/files/${data.programfile}`}
                target="_blank"
                className="download"
              >
                PDF yüklə
              </Link>
            )}
          </div>
          <div className="program">
            {data.diary.slice(0, handleCountDatas).map((program) => (
              <div className="line" key={program.id}>
                <div className="date">
                  <div className="icon">
                    <img src={clock} alt="clock" />
                  </div>
                  <div className="time">
                    {/* <span>{program.datetime.split(" ")[0]}</span>/ */}
                    <span>{program.datetime.split(" ")[1].slice(0, 5)}</span>
                  </div>
                </div>
                <div
                  className="program-title"
                  dangerouslySetInnerHTML={{
                    __html: program.description,
                  }}
                />
              </div>
            ))}
          </div>
          {Number(data.diary.length) > 4 && (
            <div
              className="btn-area"
              onClick={() => {
                if (handleCountDatas === 4) {
                  setHandleCountDatas(Number(data.diary.length));
                } else {
                  setHandleCountDatas(4);
                }
              }}
            >
              <button className="more">
                {handleCountDatas === 4
                  ? t("general.read_more")
                  : t("general.hide")}{" "}
              </button>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Program;
