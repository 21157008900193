import { useCallback, useContext, useEffect, useState } from "react";

// Icon
import addCircle from "../../assets/img/icon/add-circle.svg";
import patternDesktop from "../../assets/img/icon/payment-page-desktop-pattern.svg";
import expenditure from "../../assets/img/icon/expenditure.svg";
import income from "../../assets/img/icon/income.svg";

// Test Icon
import user from "../../assets/img/kurseviFramePhoto.svg";
import axios from "axios";
import { Auth } from "../../utils/Auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { MainContext } from "../../utils/MainContext";
const PaymentInformation = () => {
  // Global State
  const { token } = useContext(Auth);
  const { setPaymentVisible } = useContext(MainContext);

  // Local State
  //   Expenditure -false && Income-true
  const [historyType, setHistoryType] = useState(true);
  const [balance, setBalance] = useState(0);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emptyData, setEmptyData] = useState(true);

  //   Language
  const { i18n, t } = useTranslation();

  //   Navigate
  const navigate = useNavigate();

  //   Get Request
  const getBalance = useCallback(async () => {
    setLoading(true);
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/users/balance?token=${token}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setBalance(res.data.payload.sum);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          navigate("/error");
        }
      });
  }, [i18n.language, navigate, token]);

  //   Get Payments information
  const getPayments = useCallback(async () => {
    setLoading(true);
    setEmptyData(true);
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/users/payments?token=${token}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.payload.length === 0) {
            setEmptyData(true);
          } else {
            setEmptyData(false);
          }
          setListData(res.data.payload);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          navigate("/error");
        }
      });
  }, [i18n.language, navigate, token]);
  //   Get Purchases information
  const getPurchases = useCallback(async () => {
    setLoading(true);
    setEmptyData(true);
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/users/purchases?token=${token}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.payload.length === 0) {
            setEmptyData(true);
          } else {
            console.log(res.data.payload.length !== 0);
            setEmptyData(false);
          }
          setListData(res.data.payload);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          navigate("/error");
        }
      });
  }, [i18n.language, navigate, token]);

  //   Render
  useEffect(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    setListData([]);
    if (historyType) {
      getPayments();
    } else {
      getPurchases();
    }
  }, [getPayments, getPurchases, historyType]);

  const formatAmount = (amount) => {
    const formattedAmount = (parseFloat(amount) / 100).toFixed(2);
    return formattedAmount;
  };
  return (
    <>
      {loading && <Loading />}
      <section className="payment-history ">
        <div className="balance-area">
          <div className="balance-information">
            <p className="caption">{t("profil.current_balance")}</p>
            <h2 className="wallet">{balance} AZN</h2>
          </div>
          <button
            className="incriment-balance-btn"
            onClick={() => setPaymentVisible(true)}
          >
            {t("profil.balance_increases")}
            <img src={addCircle} alt="incriment balance" />
          </button>
          <div className="pattern-top-right">
            <img src={patternDesktop} alt="pattern" />
          </div>
          <div className="pattern-bottom-left">
            <img src={patternDesktop} alt="pattern" />
          </div>
        </div>
        <div className="history">
          <ul className="history-tab-list">
            <li
              className={
                historyType ? "history-tab-item active" : "history-tab-item"
              }
              onClick={() => !historyType && setHistoryType(!historyType)}
            >
            {t("profil.income")}
            </li>
            <li
              className={
                !historyType ? "history-tab-item active" : "history-tab-item"
              }
              onClick={() => historyType && setHistoryType(!historyType)}
            >
                {t("profil.expenditure")}
            </li>
          </ul>
          {emptyData ? (
            <div className="alert"> {t("profil.history_empty")}</div>
          ) : (
            <div className="history-list">
              {listData.map((data, index) => (
                <div
                  className="history-group"
                  key={index}
                  onClick={() => {
                    if (!historyType) {
                      navigate(
                        `/general-detail/${
                          data.type === 1 ? "courses" : "conferences"
                        }/${data.typeid}`
                      );
                    }
                  }}
                >
                  <div className="left-side">
                    <div className="icon">
                      <img src={user} alt="user" />
                    </div>

                    <p className="text">
                      {historyType ? t("payment.caption") : data.eventname}
                    </p>
                  </div>
                  <p className={historyType ? "money income" : "money "}>
                    {historyType ? "+" : "-"}
                    {/* */}
                    {}
                    {historyType ? formatAmount(data.amount) : data.amount} AZN
                  </p>
                  <div className="info">
                    <p className="date">
                      {data[`${historyType ? "payeddate" : "paydate"}`]
                        ?.split(" ")[0]
                        .split("-")
                        .reverse()
                        .join("/")}
                    </p>
                    <div className="alert-icon">
                      {historyType ? (
                        <img src={income} alt="income" />
                      ) : (
                        <img src={expenditure} alt="expenditure" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default PaymentInformation;
