/* ---------------------------- React Router Dom ---------------------------- */
import { Route, Routes } from "react-router-dom";

/* --------------------------------- Context -------------------------------- */
import { FormSchema } from "./utils/Auth";

/* ------------------------------- Components ------------------------------- */
import Header from "./components/Header";
import Footer from "./components/Footer";

/* ---------------------------------- Pages --------------------------------- */
import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
// import Calendar from "./pages/Calendar";
import Contact from "./pages/Contact";

/* -------------------- Conferance && Training && detail -------------------- */
import Conference from "./pages/Conference";
import Courses from "./pages/Courses";
import ConfranceCourceDetail from "./pages/ConfranceCourceDetail";
import TrainingCategory from "./pages/TrainingCategory";

/* --------------------------------- Spiker --------------------------------- */
import Spikers from "./pages/Spikers";
import SpikerDetail from "./pages/SpikerDetail";

/* -------------------------------- Partners -------------------------------- */
import Partners from "./pages/Partners";
import PartnerDetail from "./pages/PartnerDetail";

/* ------------------------------- Innovation ------------------------------- */
import InnovationsNews from "./pages/InnovationsNews";
import InnovationDetail from "./pages/InnovationDetail";
// import Profil from "./pages/profile-section/PersonalInformation";
import CalendarV2 from "./pages/CalendarV2";
import { AppContext } from "./utils/MainContext";
import Profile from "./pages/Profile";


const App = () => {
  return (
    <FormSchema>
      <AppContext>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* Calendar */}
            {/* <Route path="/calendar" element={<Calendar />} /> */}
            <Route path="/calendar" element={<CalendarV2 />} />
            
            {/* Conferance && Training && detail */}
            <Route path="/conference" element={<Conference />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/training-category" element={<TrainingCategory />} />
            <Route
              path="/general-detail/:type/:id"
              element={<ConfranceCourceDetail />}
            />

            {/*  Spiker */}
            <Route path="/spikers" element={<Spikers />} />
            <Route path="/spiker-detail/:speakerId" element={<SpikerDetail />} />

            {/* Partners */}
            <Route path="/partners" element={<Partners />} />
            <Route path="/partner-detail/:partnerId" element={<PartnerDetail />} />

            {/* Innovation  */}
            <Route path="/innovatons" element={<InnovationsNews />} />
            <Route
              path="/innovaton-detail/:id"
              element={<InnovationDetail />}
            />

            {/* Profil */}
            <Route path="/profile" element={<Profile />} />

            {/* Contact */}
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
        <Footer />
      </AppContext>
    </FormSchema>
  );
};

export default App;
