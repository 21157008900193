import { useContext } from "react";
import arrow from "../assets/img/icon/arrow-right-black.svg";
import { MainContext } from "../utils/MainContext";
import { Auth } from "../utils/Auth";
import { useTranslation } from "react-i18next";

const ProfilSideBar = () => {
  // GLobal State
  const { activeProfileTab, setActiveProfileTab } = useContext(MainContext);
  const { logOut } = useContext(Auth);

  // Translation
  const { t, i18n } = useTranslation();

  // Local State
  const list = [
    {
      type: "Hesab",
      name_az: "Hesab",
      name_en: "Profile",
      name_ru: "Профиль",
      id: 1,
    },
    {
      type: "Pul kisəsi",
      name_az: "Pul kisəsi",
      name_en: "Wallet",
      name_ru: "Кошелек",
      id: 2,
    },
    // { name: "Sertifikatlar", id: 3 },
    {
      type: "Biletlər",
      name_az: "Biletlər",
      name_en: "Tickets",
      name_ru: "Билеты",
      id: 4,
    },
    {
      type: "Arxiv",
      name_az: "Arxiv",
      name_en: "Archive",
      name_ru: "Архив",
      id: 5,
    },
  ];

  return (
    <div>
      <div className="profile-navigate">
        <ul className="profil-tab-list">
          {list.map((item) => (
            <li
              className={
                item.type === activeProfileTab.type
                  ? "profil-tab-item active"
                  : "profil-tab-item "
              }
              key={item.id}
              onClick={() =>
                item.type !== activeProfileTab.type && setActiveProfileTab(item)
              }
            >
              <span className="text">{item[`name_${i18n.language}`]}</span>
              <div className="icon">
                <img src={arrow} alt="active" />
              </div>
            </li>
          ))}
        </ul>
        <ul className="profil-tab-list">
          <li className="profil-tab-item" onClick={() => logOut()}>
            <span className="text">{t("general.log_out")}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfilSideBar;
