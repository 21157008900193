import { useContext, useEffect, useState } from "react";
import letter from "../assets/img/icon/sms-tracking (1).svg";
import { Auth } from "../utils/Auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { infoAlert } from "../utils/SweetAlert";
const Otp = () => {
  /* ------------------------------ Gloabal State ----------------------------- */
  const { setOtp, setForm,form, forgetPassowrd } = useContext(Auth);

  /* ------------------------------- Local State ------------------------------ */
  const [minute, setMinute] = useState(4);
  const [second, setSecond] = useState(59);
  // const [mesagge, setMesagge] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState("");

  // Language
  const { t ,i18n} = useTranslation();

    // REset State
    useEffect(() => {
      if (form) {
        setIsSubmitting(false);
      }
    }, [form]);

  /* ---------------------------------- Timer --------------------------------- */
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (minute === 0 && second === 0) {
  //       setMinute(0);
  //       setSecond(0);
  //     } else {
  //       if (second > 0) {
  //         setSecond(second - 1);
  //       } else {
  //         setMinute(0);
  //         setSecond(59);
  //       }
  //     }
  //   }, 1000);
  // }, [minute, second]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (minute === 0 && second === 0) {
        setMinute(0);
        setSecond(0);
      } else {
        if (second > 0) {
          setSecond(second - 1);
        } else {
          setMinute(minute - 1);
          setSecond(59);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [minute, second]);

  /* ------------------------------ Hide Message ------------------------------ */
  // useEffect(() => {
  //   const hideMessage = () => {
  //     if (forgetPassowrd.phone) {
  //       // Hiding phone number
  //       const mesagge = String(forgetPassowrd.phone);
  //       const characters = mesagge.split("");
  //       for (let i = characters.length - 6; i <= characters.length - 3; i++) {
  //         if (i >= 0) {
  //           characters[i] = "*";
  //         }
  //       }
  //       const maskedPhoneNumber = characters.join("");

  //       setMesagge(maskedPhoneNumber);
  //     } else {
  //       // Hiding Mail
  //       const atIndex = forgetPassowrd.email.indexOf("@");
  //       if (atIndex !== -1) {
  //         const username = forgetPassowrd.email.substring(0, atIndex);
  //         const domain = forgetPassowrd.email.substring(atIndex);

  //         const maskedUsername =
  //           username.substring(0, username.length - 4) + "****";
  //         const maskedEmail = maskedUsername + domain;

  //         setMesagge(maskedEmail);
  //       }
  //     }
  //   };
  //   hideMessage();
  // }, [forgetPassowrd.email, forgetPassowrd.phone]);

  const otpSchema = object({
    otp: string().required().trim(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(otpSchema),
  });
  const onSubmit = async (data) => {
    setOtp(data.otp);

    if (!isSubmitting) {
      setIsSubmitting(true);

      await axios
        .get(
          `https://api.uimconsulting.com/${i18n.language}/v3/auths/checktoken?token=${data.otp}&type=1`,
          {
            crossdomain: true,
          }
        )
        .then((res) => {
          setIsSubmitting(false);
          if (res.data.status === false) {
            setErrorText(t("otp.error"));
          } else if (res.data.status === "success") {
            setForm("change password");
          }else{
            infoAlert(t("general.failed_caption"), t("general.failed_detail"));
          }
        })
        .catch((err) => err && setIsSubmitting(false));
    }
  };

  const resendOtp = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      await axios
        .get(
          `https://api.uimconsulting.com/${i18n.language}/v3/users/passwordreset?email=${forgetPassowrd.email}`,
          {
            crossdomain: true,
          }
        )
        .then((res) => {
          if (res.data.success === "success") {
            setMinute(4);
            setSecond(59);
            setIsSubmitting(false);
          }
        })
        .catch((err) => err && setIsSubmitting(false));
    }
  };
  return (
    <div className="fotgot-otp-container">
      <div className="head-icon">
        <img src={letter} alt="letter" />
      </div>
      <div className="otp-body">
        <h5 className="otp-title">{t("otp.caption")}</h5>
        <form
          className="otp-form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="input-area">
            <fieldset className={errors.otp ? "inp error" : "inp"}>
              <legend>OTP</legend>
              <input
                type="text"
                name="otp"
                {...register("otp", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </fieldset>
            {errors.otp && (
              <div className="error-message">{errors.otp.message}</div>
            )}
            {errorText && <div className="error-message">{errorText}</div>}
          </div>
          <span className="time">
            0{minute}: {second < 10 ? `0${second}` : second}
          </span>
          <p className="text">
            {/* <span>{mesagge}</span>  */}
        {t("otp.detail")}
          </p>
          <span className="otp-forget-link" onClick={() => resendOtp()}>
            {t("otp.resend_otp")}
          </span>
          <button className="btn">  {t("otp.submit")}</button>
        </form>
      </div>
    </div>
  );
};

export default Otp;
