import { useCallback, useContext, useEffect } from "react";
// Icon
import closeMenu from "../../assets/img/icon/closeSubMenu.svg";
import arrowRightSubmenu from "../../assets/img/icon/arrowRightSubmenu.svg";
import select from "../../assets/img/icon/select.svg";

import axios from "axios";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ConferanceCourseSideBar = () => {
  // Global State
  const {
    selectCategory,
    setSelectCategory,
    filterMenu,
    setFIlterMenu,
    categoryData,
    setCategoryData,
    categoryVisible,
    setCategoryVisible,
    setRenderType,
    setSearchValue,
  } = useContext(MainContext);

  const location = useLocation();

  // reset Data
  useEffect(() => {
    if (location.pathname) {
      setFIlterMenu(false);
      setRenderType("");
      setCategoryVisible(false);
      setSearchValue("");
      setSelectCategory(null);
      setCategoryData([]);
    }
  }, [location.pathname]);

  const { t, i18n } = useTranslation();
  // Get Course Category Data
  const getCourseCategoryData = useCallback(async () => {
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/courses/categories`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          //   navigate("/error");
        }
      });
  }, [i18n.language, setCategoryData]);

  // Conferance
  const getConferanceCategoryData = useCallback(async () => {
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/conferences/categories`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          //   navigate("/error");
        }
      });
  }, [i18n.language, setCategoryData]);

  useEffect(() => {
    if (location.pathname === "/conference") {
      getConferanceCategoryData();
    } else {
      getCourseCategoryData();
    }
  }, [getConferanceCategoryData, getCourseCategoryData, location.pathname]);
  return (
    <>
      <div
        className={
          filterMenu ? "filter-menu scroll   active" : "filter-menu scroll "
        }
      >
        <div className="close" onClick={() => setFIlterMenu(false)}>
          <img src={closeMenu} alt="close" />
        </div>
        <span className="title">{t("side_bar.filter")}</span>
        <ul className="type-list">
          <li className="type-item" onClick={() => setCategoryVisible(true)}>
            <div className="text">{t("side_bar.category")}</div>
            <div className="icon">
              <img src={arrowRightSubmenu} alt="icon" />
            </div>
          </li>
        </ul>
      </div>
      <div
        className={
          categoryVisible
            ? "filter-menu sub-category   active     scroll  "
            : "filter-menu sub-category  scroll "
        }
      >
        <div className="close" onClick={() => setCategoryVisible(false)}>
          <img src={closeMenu} alt="close" />
        </div>
        <span className="title">{t("side_bar.category")}</span>
        <ul className="type-list select ">
          {categoryData.length !== 0 &&
            categoryData.map((category) => (
              <li
                className={
                  category.id === selectCategory?.id
                    ? "type-item active"
                    : "type-item"
                }
                onClick={() => {
                  if (selectCategory?.id === category.id) {
                    setSelectCategory(null);
                    setRenderType("");
                    setCategoryVisible(false);
                    setFIlterMenu(false);
                  } else {
                    setSelectCategory(category);
                    setRenderType("category");
                    setCategoryVisible(false);
                    setFIlterMenu(false);
                  }
                }}
                key={category.id}
              >
                <div className="text">{category.name}</div>
                <div className="icon">
                  <img src={select} alt="icon" />
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default ConferanceCourseSideBar;
