import { Link } from "react-router-dom";

import notFoundImg from "../assets/img/IMG-not-found.svg";
import { useState } from "react";

const PersonaolCard = ({ speaker }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Link to={`/spiker-detail/${speaker.id}`} className="personal-card">
      <div className="info">
        <h6 className="title">{speaker.fullname}</h6>
        <p className="job">{speaker.workplace}</p>
      </div>
      <div className="spiker-img">
        {!loaded && speaker.photo && <div className="placeholder"></div>}
        {speaker.photo ? (
          <img
            src={`https://www.uimconsulting.com/uploads/speakers/${speaker.photo}`}
            alt={speaker.fullname}
            fetchpriority="high"
            onLoad={() => setLoaded(true)}
            style={{ display: loaded ? "block" : "none" }}
          />
        ) : (
          <img className="not-found" src={notFoundImg} alt={speaker.fullname} />
        )}
      </div>
    </Link>
  );
};

export default PersonaolCard;
