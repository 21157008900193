import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useCallback, useContext } from "react";
import { MainContext } from "../utils/MainContext";
import axios from "axios";
import { Auth } from "../utils/Auth";
import { useTranslation } from "react-i18next";
import { infoAlert } from "../utils/SweetAlert";
import { useLocation } from "react-router-dom";

const Payment = () => {
  // Global State
  const { setPaymentVisible } = useContext(MainContext);
  const { token } = useContext(Auth);

  //   Translation
  const { i18n, t } = useTranslation();

  // Location
  const location = useLocation();

  console.log();

  //   Yup Schema
  const profileSchema = object({
    pay: string().required().trim(),
  });

  //   REact Hook Form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
  });

  //   Send Request for Increase Balance
  const sendQuery = async (data) => {
    await axios
      .post(
        `https://api.uimconsulting.com/${i18n.language}/v3/dds/replenish?token=${token}`,
        {
          amount: data.pay,
        }
      )
      .then((res) => {
        if (res.data.status) {
          window.location.href = res.data.link;
        }
      })
      .catch((err) => {
        setPaymentVisible(false);
        infoAlertSchema(
          t("general.failed_caption"),
          t("general.failed_detail")
        );
      });
  };

  // Info Alert
  const infoAlertSchema = useCallback((caption, text) => {
    infoAlert(caption, text);
  }, []);

  return (
    <div
      className="payment"
      onClick={() => {
        setPaymentVisible(false);
        if (!location.pathname.split("/").includes("profile")) {
          infoAlertSchema(
            t("payment.reserve_caption"),
            t("payment.reserve_detail")
          );
        }
      }}
    >
      <form
        className="payment-boy"
        data-aos="fade-up"
        data-aos-duration="900"
        onSubmit={handleSubmit(sendQuery)}
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="title">{t("payment.increase_balance")}</h2>
        <fieldset className="form-group">
          <legend
            className={
              watch("pay") === undefined || watch("pay") === ""
                ? "label"
                : "label animate"
            }
          >
            {t("payment.total")}:
          </legend>
          <input type="number" name="pay" {...register("pay")} />
          {errors.pay && (
            <div className="error-message">{t("payment.error")}</div>
          )}
        </fieldset>
        <button className="submit">{t("payment.submit")} </button>

        {!location.pathname.split("/").includes("profile") && (
          <p className="alert">{t("payment.alert")}</p>
        )}
      </form>
    </div>
  );
};

export default Payment;
