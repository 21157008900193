import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../utils/MainContext";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from "../utils/Auth";

// Icon
import close from "../assets/img/icon/sidebar-close.svg";
import user from "../assets/img/icon/user.svg";
import logo from "../assets/img/Kurs evi logo.svg";

const MobileSideBar = () => {
  // Global State
  const { logOut, token,   profilData, } = useContext(Auth);
  const {
    sideBar,
    setSideBar,
    activeProfileTab,
    setActiveProfileTab,

  } = useContext(MainContext);

  //   Local State
  const [langOpen, setLangOpen] = useState(false);
  const list = [
    {
      type: "Hesab",
      name_az: "Hesab",
      name_en: "Profile",
      name_ru: "Профиль",
      id: 1,
    },
    {
      type: "Pul kisəsi",
      name_az: "Pul kisəsi",
      name_en: "Wallet",
      name_ru: "Кошелек",
      id: 2,
    },
    // { name: "Sertifikatlar", id: 3 },
    {
      type: "Biletlər",
      name_az: "Biletlər",
      name_en: "Tickets",
      name_ru: "Билеты",
      id: 4,
    },
    {
      type: "Arxiv",
      name_az: "Arxiv",
      name_en: "Archive",
      name_ru: "Архив",
      id: 5,
    },
  ];
  // Language
  const { t, i18n } = useTranslation();

  // React Router Dom
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setSideBar(false);
    }
  }, [location.pathname]);

  // Change Language
  const selectLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSideBar(false);
  };

  return (
    <aside
      className={sideBar ? "side-bar isOpenMenu scroll" : "side-bar scroll"}
    >
      <div className="mobile-container">
        <div className="head">
          <Link to="/" className="logo">
            <img src={logo} alt="kursevi" />
          </Link>
          <div className="close-icon" onClick={() => setSideBar(false)}>
            <img src={close} alt="close-sidebar" />
          </div>
        </div>
        <div className="body">
          {profilData && token && (
            <div className="user-info">
              <div className="user-icon">
                <img src={user} alt="user" />
              </div>
              <h2 className="user-fullname">{profilData.fullname}</h2>
            </div>
          )}

          <ul className="mobile-list">
            { token && list.map((profilMenu) => (
              <li className="mobile-items" key={profilMenu.id}>
                <Link
                  className={
                    activeProfileTab.type === profilMenu.type &&
                    location.pathname.split("/").includes('profile')
                      ? "mobile-nav active"
                      : "mobile-nav "
                  }
                  onClick={() => {
                    setActiveProfileTab(profilMenu);
                    setSideBar(false);
                  }}
                  to="/profile"
                >
                  {profilMenu[`name_${i18n.language}`]}
                </Link>
              </li>
            ))}

            <li className="mobile-items">
              <NavLink className="mobile-nav" to="/">
                {t("header.home")}
              </NavLink>
            </li>
            <li className="mobile-items">
              <NavLink className="mobile-nav" to="/calendar">{t("header.calendar")}</NavLink>
            </li>
            <li className="mobile-items">
              <NavLink className="mobile-nav" to="/conference">
                {t("header.conference")}
              </NavLink>
            </li>
            <li className="mobile-items">
              <NavLink className="mobile-nav" to="/courses">
                {t("header.courses")}
              </NavLink>
            </li>
            <li className="mobile-items">
              <NavLink className="mobile-nav" to="/partners">
                {t("header.partners")}
              </NavLink>
            </li>
            <li className="mobile-items">
              <NavLink className="mobile-nav" to="/innovatons">
                {t("header.innovatons")}
              </NavLink>
            </li>
            <li className="mobile-items">
              <NavLink className="mobile-nav" to="/contact">
                {t("header.contact")}
              </NavLink>
            </li>
          </ul>
          <div className="language" onClick={(e) => setLangOpen(!langOpen)}>
            <div className="lang-icon icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.9961 17.2803C13.1382 17.2803 16.4961 13.9224 16.4961 9.78028C16.4961 5.63814 13.1382 2.28027 8.9961 2.28027C4.85396 2.28027 1.49609 5.63814 1.49609 9.78028C1.49609 13.9224 4.85396 17.2803 8.9961 17.2803Z"
                  stroke="#001D48"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.99922 3.03027H6.74922C5.28672 7.41028 5.28672 12.1503 6.74922 16.5303H5.99922"
                  stroke="#001D48"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.25 3.03027C12.7125 7.41028 12.7125 12.1503 11.25 16.5303"
                  stroke="#001D48"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.24609 12.7803V12.0303C6.6261 13.4928 11.3661 13.4928 15.7461 12.0303V12.7803"
                  stroke="#001D48"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.24609 7.53047C6.6261 6.06797 11.3661 6.06797 15.7461 7.53047"
                  stroke="#001D48"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="curent-lang">
              {i18n.language === "az"
                ? "Azərbaycan"
                : i18n.language === "en"
                ? "English"
                : "Русский"}
              <ul className={langOpen ? "lang-list active" : "lang-list "}>
                <li
                  className="lang-item"
                  onClick={() => {
                    setLangOpen(false);
                    selectLanguage("az");
                  }}
                >
                  Azərbaycan
                </li>
                <li
                  className="lang-item "
                  onClick={() => {
                    setLangOpen(false);
                    selectLanguage("en");
                  }}
                >
                  English
                </li>
                {/* <li
                  className="lang-item "
                  onClick={() => {
                    setLangOpen(false);
                    selectLanguage("ru");
                  }}
                >
                  Русский
                </li> */}
              </ul>
            </span>
            <div
              className={
                langOpen ? "drop-icon icon active-icon" : "drop-icon icon"
              }
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9984 5.37939L8.04692 9.78884C7.58026 10.3096 6.81662 10.3096 6.34995 9.78884L2.39844 5.37939"
                  stroke="#001D48"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          {token && (
            <button
              className="logout"
              onClick={() => {
                logOut();
                setSideBar(false);
              }}
            >
              {t("general.log_out")}
            </button>
          )}
        </div>
      </div>
    </aside>
  );
};

export default MobileSideBar;
