// import GoogleMapReact from "google-map-react";
import { Link, useNavigate } from "react-router-dom";

import img1 from "../assets/img/contact/Frame 702.png";
import img2 from "../assets/img/contact/Frame 702 (1).png";
import img3 from "../assets/img/contact/Frame 702 (2).png";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MainContext } from "../utils/MainContext";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";

const Contact = () => {
  // Gloval State
  const { contactData } = useContext(MainContext);

  // Local State
  const [loading, setLoading] = useState(false);

  // const AnyReactComponent = ({ text }) => <div>{text}</div>;
  // const defaultProps = {
  //   center: {
  //     lat: 40.37809,
  //     lng: 49.840247,
  //   },
  //   zoom: 16,
  // };

  // Navigate
  const navigate = useNavigate();

  // Render Data
  useEffect(() => {
    setLoading(true);

    if (contactData && "alert" in contactData) {
      setLoading(false);
      navigate("/error");
    } else if (contactData && "phone" in contactData) {
      setLoading(false);
    }
  }, [contactData, navigate]);
  const { t } = useTranslation();

  return (
    <section className="contact">
      {loading && <Loading />}
      <div className="container">
        <h6 className="caption">{t("contact.caption")}</h6>
        {/* <div className="map">
            <GoogleMapReact
              bootstrapURLKeys={{ key:"" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent lat={40.37809} lng={49.840247} />
            </GoogleMapReact>
          </div> */}
      </div>
      {contactData && (
        <div className="contact-info">
          <div className="container">
            <div className="contact-area">
              <div className="contact-card">
                <div className="icon">
                  <img src={img1} alt="contact-phone" />
                </div>
                <div className="information">
                  <ul className="link-list">
                    <li className="link-item">
                      <span className="text">{t("contact.phone")}:</span>
                      <Link to={`tel:${contactData.phone}`} target="_blank">
                        {contactData.phone}
                      </Link>
                    </li>
                    <li className="link-item">
                      <span className="text">{t("contact.phone_mobile")}:</span>
                      <Link to={`tel:${contactData.mobile}`} target="_blank">
                        {contactData.mobile}
                      </Link>
                    </li>
                    <li className="link-item">
                      <span className="text">Whatsapp:</span>
                      <Link
                        to={`https://wa.me/${contactData.whatsapp}`}
                        target="_blank"
                      >
                        {contactData.whatsapp}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="contact-card">
                <div className="icon">
                  <img src={img2} alt="contact-phone" />
                </div>
                <div className="information">
                  <ul className="link-list">
                    <li className="link-item">
                      <span className="text">{t("contact.email")}: </span>
                      <Link to={`mailto:${contactData.email}`}>
                        {contactData.email}
                      </Link>
                    </li>
                    <li className="link-item">
                      <span className="text">{t("contact.email")}: </span>
                      <Link to={`mailto:${contactData.email2}`}>
                        {contactData.email2}
                      </Link>
                    </li>
                    <li className="link-item">
                      <span className="text">Facebook: </span>
                      <Link to={contactData.facebook} target="_blank">
                        {contactData.facebook}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="contact-card">
                <div className="icon">
                  <img src={img3} alt="contact-phone" />
                </div>
                <div className="information">
                  <ul className="link-list">
                    <li className="link-item">
                      <span className="text">{t("contact.address")}: </span>
                      <Link to={contactData.googlemap} target="_blank">
                        {contactData.address_en}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Contact;
