// React
import { useContext, useEffect, useState } from "react";

// Context
import { Auth } from "../utils/Auth";

// React Hook Form && Yup
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

// AXios
import axios from "axios";

// Icon
import lock from "../assets/img/icon/lock.svg";
import { useTranslation } from "react-i18next";

const ForgetPassword = () => {
  // Global State
  const { form, setForm, setForgetPassowrd } = useContext(Auth);

  // Local State
  // const [choose, setChoose] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState("");

  // Translation
  const { t,i18n } = useTranslation();

  // Reset State
  useEffect(() => {
    if (form === "forgot") {
      setIsSubmitting(false);
    }
  }, [form]);

  // Yup Schema
  // const phoneShema = object({
  //   phone: string().required().trim(),
  // });
  const emailSchema = object({
    email: string().required().email(),
  });

  // React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
    // resolver: yupResolver(choose ? emailSchema : phoneShema),
  });

  // Send Request For Forget PAssword
  const submitForget = async (data) => {
    // if (choose) {
    //   setForgetPassowrd({ email: data.email });
    // } else {
    //   setForgetPassowrd({ phone: data.phone });
    // }
    setForgetPassowrd({ email: data.email });
    if (!isSubmitting) {
      setIsSubmitting(true);

      await axios
        .get(
          `https://api.uimconsulting.com/${i18n.language}/v3/users/passwordreset?email=${data.email}`,
          {
            crossdomain: true,
          }
        )
        .then((res) => {
          if (res.data.status === false) {
            setErrorText(t("forget_password.error"));
            setIsSubmitting(false);
          }
          if (res.data.success) {
            setForm("otp");
          }
        })
        .catch((err) => err && setIsSubmitting(false));
    }

    // setForm("otp");
    // `https://api.uimconsulting.com/${i18n.language}/v3/users/passwordreset?email=${
    //         choose ? data.email : data.phone
    //       }`
  };

  return (
    <div className="fotgot-otp-container">
      <div className="head-icon">
        <img src={lock} alt="lock" />
      </div>
      <div className="forget-body">
        <h5 className="fotget-title"> {t("forget_password.forget_pass")}</h5>
        <div className="forget-area">
          <div
            className="forget-mail active"
            // className={choose === true ? "forget-mail active" : "forget-mail "}
          >
            <p className="forget-text">{t("forget_password.detail")}</p>
            <form className="forget-form" onSubmit={handleSubmit(submitForget)}>
              <div className="form-group">
                <label>{t("forget_password.mail")}</label>
                <input
                  type="email"
                  name="email"
                  placeholder={t("forget_password.mail")}
                  className={errors.email && "error"}
                  autoComplete="off"
                  {...register("email", {
                    onChange: (e) => {
                      errorText !== "" && setErrorText("");
                    },
                  })}
                />
                {errors.email && (
                  <div className="error-message">{errors.email.message}</div>
                )}
                {errorText && <div className="error-message">{errorText}</div>}
              </div>
              {/* <span className="forget-link" onClick={() => setChoose(false)}>
                Mobil nömrə ilə yenilə
              </span> */}

              <button className="btn">{t("forget_password.submit")}</button>
              <p className="text">
                {t("forget_password.have_an_account")}
                <span
                  className="otp-forget-link"
                  onClick={() => {
                    setForm("sing in");
                  }}
                >
                  {t("forget_password.login")}
                </span>
              </p>
            </form>
          </div>
          {/* <div
            className={
              choose === false ? "forget-phone active" : "forget-phone"
            }
          >
            <p className="forget-text">
              Telefon nömrənizi daxil edərək hesabınızdan yenidən istifadə edə
              bilərsiniz
            </p>
            <form className="forget-form" onSubmit={handleSubmit(submitForget)}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  name="phone"
                  placeholder="phone"
                  className={errors.phone && "error"}
                  {...register("phone")}
                />
                {errors.phone && (
                  <div className="error-message">{errors.phone.message}</div>
                )}
              </div>
              <span className="forget-link" onClick={() => setChoose(true)}>
                Mail ilə yenilə
              </span>
              <button className="btn">Kod göndər</button>
              <p className="text">
                Hesabınız var?
                <span
                  className="otp-forget-link"
                  onClick={() => {
                    setForm("sing in");
                  }}
                >
                  Daxil ol
                </span>
              </p>
            </form>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
