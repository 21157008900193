import React, { useContext } from "react";
import ProfilSideBar from "../components/ProfilSideBar";
import { MainContext } from "../utils/MainContext";
import PersonalInformation from "./profile-section/PersonalInformation";
import PaymentInformation from "./profile-section/PaymentInformation";
import Payment from "../components/Payment";
import AllTicket from "./profile-section/AllTicket";
import History from "./profile-section/History";

const Profile = () => {
  // Global State
  const { activeProfileTab, paymentVisible } = useContext(MainContext);

  return (
    <section className="profile-content">
      <div className="container">
        <ProfilSideBar />
        <div className="profile-body">
          {activeProfileTab.type === "Hesab" ? (
            <PersonalInformation />
          ) : activeProfileTab.type === "Pul kisəsi" ? (
            <PaymentInformation />
          ) : activeProfileTab.type === "Biletlər" ? (
            <AllTicket />
          ) : activeProfileTab.type === "Arxiv" ? (
            <History />
          ) : null}

          {paymentVisible && <Payment />}
        </div>
      </div>
    </section>
  );
};

export default Profile;
