import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../utils/MainContext";

const DetailBanner = ({ data, type }) => {
  // Global State
  const { scrollToSection, setTicketVisible } = useContext(MainContext);

  const { t } = useTranslation();
  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(data.startdate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0 };
    }

    return timeLeft;
  }, [data.startdate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeLeft(calculateTimeLeft()); 

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 60000); 

    return () => clearInterval(timer);
  }, [calculateTimeLeft, data.startdate]);

  return (
    <section className="detail-banner">
      <div className="container">
        <div className={`row ${data.status !== 0 ? "finish" : ""}`}>
          {data.status === 0 && (
            <div className="banner-info">
              {data.participant.status && (
                <h4 className="status-alert">
                  {data.participant.status === 3
                    ? t("banner_detail.register_succes")
                    : t("banner_detail.rezerve_succes")}
                  : “{data.participant.type}”
                </h4>
              )}

              <div className="date">
                <div className="day date-box">
                  <div className="date-info">
                    <span className="time">{timeLeft.days}</span>
                    <p className="text">{t("banner_detail.day")}</p>
                  </div>
                </div>
                <div className="hour  date-box">
                  <div className="date-info">
                    <span className="time">{timeLeft.hours}</span>
                    <p className="text">{t("banner_detail.hours")}</p>
                  </div>
                </div>
                <div className="minute  date-box">
                  <div className="date-info">
                    <span className="time">{timeLeft.minutes}</span>
                    <p className="text">{t("banner_detail.minutes")}</p>
                  </div>
                </div>
              </div>
              <h3 className="title">{data.name} </h3>

              {data.prices !== "None" && (
                <button
                  className={
                    data.participant.status === 3 ? "btn complete" : "btn"
                  }
                  onClick={() =>
                    data.participant.status === 3
                      ? setTicketVisible(true)
                      : scrollToSection()
                  }
                >
                  {data.participant.status === 3
                    ? t("banner_detail.ticket_watch")
                    : t("banner_detail.register")}
                </button>
              )}
            </div>
          )}

          <div className="banner-img">
            <img
              src={`https://uimconsulting.com/uploads/${type}/images/${data.image}`}
              alt={data.category}
            />
          </div>
        </div>
      </div>
      {data.status !== 0 && <div className="finish-mode-name">{data.name}</div>}
    </section>
  );
};

export default DetailBanner;
