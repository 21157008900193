import { useCallback, useContext, useEffect, useState } from "react";
import arrowDown from "../../assets/img/icon/arrow-down.svg";
import axios from "axios";
import { Auth } from "../../utils/Auth";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { infoAlert, submitAlert } from "../../utils/SweetAlert";
import { MainContext } from "../../utils/MainContext";

const ChoosePacket = () => {
  // Global State
  const { token, setFormArea, setForm } = useContext(Auth);
  const {
    scrollRef,
    courseConferanceData,
    setCourseConferanceData,
    setPaymentVisible,
    setActiveProfileTab,
  } = useContext(MainContext);

  // Local State
  const [balance, setBalance] = useState("");
  const [selectBoxHeight, setSelectBoxHeight] = useState(null);

  // Language
  const { t, i18n } = useTranslation();

  // Params
  const { type } = useParams();

  // Navigate
  const navigate = useNavigate();

  // Request Schema For Balance
  const balanceRequset = useCallback(async () => {
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/users/balance?token=${token}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setBalance(res.data.payload.sum);
        }
      })
      .catch((err) => console.log(err));
  }, [i18n.language, token]);

  // Send Request while page Loading
  useEffect(() => {
    if (token) {
      balanceRequset();
    }
  }, [balanceRequset, token]);

  let openSelect = (e) => {
    if (selectBoxHeight) {
      setSelectBoxHeight(null);
    } else {
      setSelectBoxHeight(
        e.currentTarget.nextElementSibling.children[0].clientHeight
      );
    }
  };

  const payFunction = (packet) => {
    if (balance !== "") {
      if (Number(balance) >= Number(packet.price)) {
        submitAlert(
          `${t("alert.balance")}:  ${balance} Azn`,
          `${packet.name} , ${t("alert.pay_confirm_request")}`,
          t("alert.yes"),
          async () => {
            await axios
              .post(
                `https://api.uimconsulting.com/${
                  i18n.language
                }/v3/dds/pay?token=${token}&type=${
                  type === "courses" ? "1" : "2"
                }&id=${courseConferanceData.id}`
              )
              .then((res) => {
                if (res.data.status === "success") {
                  // window.location.reload();
                  setCourseConferanceData((prev) => ({
                    ...prev,
                    participant: {
                      status: 3,
                      type: packet.name,
                    },
                  }));
                  infoAlert(t("alert.notification"), t("alert.reg_success"));
                  setTimeout(() => {
                    window.location.reload();
                  }, 2500);
                }
              })
              .catch((err) => console.log(err));
          },
          async () => {
            infoAlert(
              t("payment.reserve_caption"),
              t("payment.reserve_detail")
            );
          }
        );
      } else {
        setPaymentVisible(true);
      }
    }
  };

  // Join Cancel Course
  const joinCourse = async (packet, eventType) => {
    const body = {
      course_id: courseConferanceData.id,
      type: packet.type,
    };
    await axios
      .post(
        `https://api.uimconsulting.com/${i18n.language}/v3/courses/join?token=${token}`,
        body
      )
      .then((res) => {
        if (res.data.status === "success") {
          setCourseConferanceData((prev) => ({
            ...prev,
            participant: {
              status: 1,
              type: packet.name,
            },
          }));
          if (eventType === "pay") {
            payFunction(packet);
          }

          // payment
        } else if (res.data.code === 2099) {
          submitAlert(
            t("alert.failed_caption"),
            t("alert.complete_profil"),
            t("alert.navigate_profile"),
            () => {
              setActiveProfileTab({
                type: "Hesab",
                name_az: "Hesab",
                name_en: "Profile",
                name_ru: "Профиль",
                id: 1,
              });
              navigate("/profile");
            },
            () => {}
          );
        } else if (res.data.code === 3003) {
          infoAlert(t("alert.failed_caption"), t("alert.reg_open_soon"));
        } else if (res.data.code === 9001) {
          infoAlert(t("alert.failed_caption"), t("alert.reg_places_full"));
        } else if (res.data.code === 9002) {
          if (eventType === "pay") {
            payFunction(packet);
          }
        }
      })
      .catch(
        (err) =>
          err &&
          infoAlert(t("general.failed_caption"), t("general.failed_detail"))
      );
  };
  const cancelCourse = async (packet, eventType) => {
    if (courseConferanceData.participant.status !== 3) {
      await axios
        .post(
          `https://api.uimconsulting.com/${i18n.language}/v3/courses/joincancel?token=${token}`,
          {
            course_id: courseConferanceData.id,
          }
        )
        .then((res) => {
          // Succes Status
          if (res.data.status === "success") {
            setCourseConferanceData((prev) => ({
              ...prev,
              participant: "None",
            }));

            // Change Packet While Click Diferent Packet
            if (eventType === "change-reserve") {
              joinCourse(packet, eventType);
            }
          }
          // Error Status
          else {
            infoAlert(
              t("alert.failed_caption"),
              t("alert.contact_our_staff_error")
            );
          }
        })
        .catch(
          (err) =>
            err &&
            infoAlert(t("general.failed_caption"), t("general.failed_detail"))
        );
    }
  };

  // Join Cancel Conference
  const joinConferance = async (packet, eventType) => {
    const body = {
      conference_id: courseConferanceData.id,
      type: packet.type,
    };
    await axios
      .post(
        `https://api.uimconsulting.com/${i18n.language}/v3/conferences/join?token=${token}`,
        body
      )
      .then((res) => {
        if (res.data.status === "success") {
          // window.location.reload();
          setCourseConferanceData((prev) => ({
            ...prev,
            participant: {
              status: 1,
              type: packet.name,
            },
          }));

          if (eventType === "pay") {
            payFunction(packet);
          }

          // payment
        } else if (res.data.code === 2099) {
          submitAlert(
            t("alert.failed_caption"),
            t("alert.complete_profil"),
            t("alert.navigate_profile"),
            () => navigate("/profile"),
            () => {}
          );
        } else if (res.data.code === 3003) {
          infoAlert(t("alert.failed_caption"), t("alert.reg_open_soon"));
        } else if (res.data.code === 9001) {
          infoAlert(t("alert.failed_caption"), t("alert.reg_places_full"));
        } else if (res.data.code === 9002) {
          if (eventType === "pay") {
            payFunction(packet);
          }
        }
      })
      .catch(
        (err) =>
          err &&
          infoAlert(t("general.failed_caption"), t("general.failed_detail"))
      );
  };
  const cancelConferance = async (packet, eventType) => {
    if (courseConferanceData.participant.status !== 3) {
      await axios
        .post(
          `https://api.uimconsulting.com/${i18n.language}/v3/conferences/joincancel?token=${token}`,
          {
            conference_id: courseConferanceData.id,
          }
        )
        .then((res) => {
          // Succes Status
          if (res.data.status === "success") {
            setCourseConferanceData((prev) => ({
              ...prev,
              participant: "None",
            }));
            // Change Packet While Click Diferent PacketF
            if (eventType === "change-reserve") {
              joinConferance(packet, eventType);
            }
          }
          // Error Status
          else {
            infoAlert(
              t("alert.failed_caption"),
              t("alert.contact_our_staff_error")
            );
          }
        })
        .catch(
          (err) =>
            err &&
            infoAlert(t("general.failed_caption"), t("general.failed_detail"))
        );
    }
  };

  // Control  Join && Cancel && Course && Conferance && Pay
  const controle = (packet, eventType) => {
    //
    // Check Login
    //
    if (token === "") {
      setForm("sing in");
      setFormArea(true);
    } 
    // 
    // Check Balance
    // 
    else if (Number(balance) < Number(packet.price) && eventType === "pay") {
      submitAlert(
        t("alert.notification"),
        t("alert.increase_balance"),
        t("alert.increase_balance_btn"),
        () => {
          navigate("/profile");
          setActiveProfileTab({
            type: "Pul kisəsi",
            name_az: "Pul kisəsi",
            name_en: "Wallet",
            name_ru: "Кошелек",
            id: 2,
          });
        },
        () => {}
      );
    }
    //
    // Conference Validation
    //
    else if (
      type === "conferences" &&
      courseConferanceData.participant.status !== 3
    ) {
      if (eventType === "pay" || eventType === "reserve") {
        joinConferance(packet, eventType);
      } else if (
        eventType === "cancel-reserve" ||
        eventType === "change-reserve"
      ) {
        cancelConferance(packet, eventType);
      }

      // pay while rezerved>join=pay
      // reserve >join=reserve
      // change rezerv  >cancel=change-reserve
      // direct-pay=join=pay
    }
    //
    // Courses Validation
    //
    else if (
      type === "courses" &&
      courseConferanceData.participant.status !== 3
    ) {
      if (eventType === "pay" || eventType === "reserve") {
        joinCourse(packet, eventType);
      } else if (
        eventType === "cancel-reserve" ||
        eventType === "change-reserve"
      ) {
        cancelCourse(packet, eventType);
      }
    }
    //
    // Failed
    //
    else {
      infoAlert(t("alert.failed_caption"), t("alert.contact_our_staff_error"));
    }
  };

  const rezerveBtnHandle = (packet) => {
    if (courseConferanceData.participant === "None") {
      controle(packet, "reserve");
    } else if (
      courseConferanceData.participant !== "None" &&
      courseConferanceData.participant.type === packet.name
    ) {
      controle(packet, "cancel-reserve");
    } else if (
      courseConferanceData.participant !== "None" &&
      courseConferanceData.participant.type !== packet.name
    ) {
      controle(packet, "change-reserve");
    } else {
      return null;
    }
  };
  const payBtnHandle = (packet) => {
    if (courseConferanceData.participant.status !== 3) {
      if (
        courseConferanceData.participant === "None" ||
        (courseConferanceData.participant !== "None" &&
          courseConferanceData.participant.type === packet.name)
      ) {
        controle(packet, "pay");
      } else if (
        courseConferanceData.participant !== "None" &&
        courseConferanceData.participant.type !== packet.name
      ) {
        controle(packet, "change-reserve");
      } else {
        return null;
      }
    }
  };

  return (
    <section className="conferance-course-choose-packet" ref={scrollRef}>
      <div className="container">
        <h6 className="caption">{t("choose_packet.caption")}</h6>
        <div className="select" onClick={(e) => openSelect(e)}>
          <p className="text">
            {courseConferanceData.participant.status === 3
              ? courseConferanceData.participant.type
              : t("payment.participation_type")}
          </p>
          {courseConferanceData.participant.status === 3 ? (
            <p className="pay-info-alert">{t("payment.payment_made")}</p>
          ) : (
            <div className="icon">
              <img src={arrowDown} alt="arrowDown" />
            </div>
          )}
        </div>
        <div
          className={
            selectBoxHeight !== null
              ? "select-box avtiveSelectBox "
              : "select-box"
          }
          style={{ height: selectBoxHeight ? selectBoxHeight : 0 }}
        >
          <ul className="select-list">
            {courseConferanceData?.prices.map((price) => (
              <li className="select-item" key={price.type}>
                <div className="price-info-area">
                  <p className="text">{price.name}</p>
                  <span className="money">{price.price} AZN</span>
                </div>
                <div className="btn-gorup">
                  {courseConferanceData.participant.status !== 3 && (
                    <button
                      className={
                        courseConferanceData.participant !== "None" &&
                        courseConferanceData.participant.type === price.name
                          ? "reserve cancel"
                          : "reserve"
                      }
                      onClick={() => {
                        rezerveBtnHandle(price);
                      }}
                    >
                      {courseConferanceData.participant !== "None" &&
                      courseConferanceData.participant.type === price.name
                        ? t("payment.reserve_cancel")
                        : t("payment.reserve")}
                    </button>
                  )}

                  <button
                    className={`pay ${
                      courseConferanceData.participant.status === 3 &&
                      courseConferanceData.participant.type !== price.name
                        ? "disabled"
                        : ""
                    } `}
                    onClick={() => {
                      payBtnHandle(price);
                    }}
                  >
                    {courseConferanceData.participant.status === 3 &&
                    courseConferanceData.participant.type === price.name
                      ? t("payment.payment_made")
                      : t("payment.pay")}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div
          className="alert"
          dangerouslySetInnerHTML={{
            __html: courseConferanceData.prerequisite,
          }}
        />
      </div>
    </section>
  );
};

export default ChoosePacket;
// courseConferanceData.participant.status !== 3 &&
//   courseConferanceData.participant !== "None" &&
//   courseConferanceData.participant.type === price.name && (
//     <span>{t("alert.reserve")}</span>

// className={
//   courseConferanceData.participant.status === 3 &&
//   courseConferanceData.participant.type === price.name
//     ? "select-item active"
//     : "select-item"
// }
