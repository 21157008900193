import React, { useCallback, useEffect, useState } from "react";
import DetailCardArea from "../components/DetailCardArea";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
const PartnerDetail = () => {
  // LocalState
  const [partnerData, setPartnerData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Params
  const { partnerId } = useParams();

  // Navigate
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  // Get Partner Data
  const getPartnerData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/partners/${partnerId}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setPartnerData(res.data);
        setLoading(false);
        console.log();
      })
      .catch((err) => {
        if (err) {
          navigate("/error");
          setLoading(false);
        }
      });
  }, [i18n.language, navigate, partnerId]);

  useEffect(() => {
    getPartnerData();
  }, [getPartnerData]);

  return (
    <>
      {loading && <Loading />}
      <section
        className={`partner-gneral-info ${
          typeof partnerData?.conferences === "string" ||
          typeof partnerData?.courses === "string"
            ? "large"
            : ""
        } `}
        //   large konfran ve telimin olub olmamasindan asili ele,yoxdusa large classini elave ele
      >
        {partnerData && (
          <div className="container">
            <div className="partner-info">
              <div className="partner-img">
                <div className="image-box">
                  <img
                    src={`https://uimconsulting.com/uploads/partners/${partnerData.image}`}
                    alt={partnerData.name}
                  />
                </div>
              </div>
              <p className="partner-name">{partnerData.name}</p>
            </div>
            <div className="partner-about">
              <h6 className="caption">{t("general.about")}</h6>
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: partnerData.description }}
              />
            </div>
          </div>
        )}
      </section>
      {partnerData && (
        <>
          {typeof partnerData.conferences !== "string" && (
            // partnerData.conferences.length !== 0
            <DetailCardArea
              actionType={"conferneces"}
              datas={partnerData.conferences}
            />
          )}
          {typeof partnerData.courses !== "string" && (
            // partnerData.courses.length !== 0
            <DetailCardArea
              actionType={"courses"}
              datas={partnerData.courses}
            />
          )}
        </>
      )}
    </>
  );
};

export default PartnerDetail;
