import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";

const InnovationDetail = () => {
  // Local State
  const [newsData, setNewsData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Params
  const { id } = useParams();

  // Navigate
  const navigate = useNavigate();

  const{i18n}=useTranslation()

  const getNewsListData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`https://api.uimconsulting.com/${i18n.language}/v3/newslists/${id}`, {
        crossdomain: true,
      })
      .then((res) => {
        setNewsData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          navigate("/error");
          setLoading(false);
        }
      });
  }, [i18n.language, id, navigate]);
  useEffect(() => {
    if (id) {
      getNewsListData();
    }
  }, [getNewsListData, id]);

  return (
    <section className="innovation-detail">
      {loading && <Loading />}
      {newsData && (
        <div className="container">
          <div className="row">
            <div className="innovation-img">
              <img
                src={`https://uimconsulting.com/uploads/news/${newsData.image}`}
                alt={newsData.title}
              />
            </div>
            <div className="innovation-info">
              <h3 className="title">
                {newsData.title}
              </h3>
              <div className="date">
                <span className="day-mounth">
                  {" "}
                  {newsData.date.split(" ")[0]}
                </span>
              </div>
            </div>
          </div>
          <div className="innovation-text">
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: newsData.text }}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default InnovationDetail;
