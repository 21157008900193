import React, { useCallback, useContext, useEffect, useState } from "react";
import { Auth } from "../../utils/Auth";
import { useTranslation } from "react-i18next";
import axios from "axios";

// Logo
import logo from "../../assets/img/kurseviFramePhoto.svg";
import pdf from "../../assets/img/icon/document-download.svg";
import { useNavigate } from "react-router-dom";
import AllTicketLoader from "../confrance-cource-detail/AllTicketLoader";
import { MainContext } from "../../utils/MainContext";
import Ticket from "../../components/Ticket";

const AllTicket = () => {
  // Global Data
  const { token } = useContext(Auth);
  const { ticketVisible, setTicketVisible } = useContext(MainContext);

  //   Local State
  const [type, setType] = useState("conferences");
  const [dataList, setDataList] = useState(["loading"]);
  const [selectAction, setSelectAction] = useState(null);

  // Language
  const { t, i18n } = useTranslation();

  // React Router DOm
  const navigate = useNavigate();

  //   Get Request For Registeried Courses
  const getMyCoursesConference = useCallback(async () => {
    setDataList(["loading"]);
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/${type}/participant?token=${token}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        const filteredData = res.data.filter((item) =>
          type === "courses"
            ? item.status === "Payed" || item.status === "Ödənildi"
            : item.status === 3
        );
        setDataList(filteredData);
      })
      .catch((err) => {
        if (err) {
          setDataList(["loading"]);
          navigate("/error");
        }
      });
  }, [i18n.language, navigate, token, type]);

  // Render Data
  useEffect(() => {
    setDataList([]);
    if (token && i18n.language) {
      getMyCoursesConference();
    }
  }, [getMyCoursesConference, i18n.language, token, type]);
  return (
    <>
      <section className="all-ticket">
        <ul className="type-tab">
          <li
            className={
              type === "conferences" ? "type-tab-item active" : "type-tab-item"
            }
            onClick={() => {
              if (type !== "conferences") {
                setType("conferences");
                setDataList([]);
              }
            }}
          >
            {t("header.conference")}
          </li>
          <li
            className={
              type !== "conferences" ? "type-tab-item active" : "type-tab-item"
            }
            onClick={() => {
              if (type === "conferences") {
                setType("courses");
                setDataList([]);
              }
            }}
          >
            {t("header.courses")}
          </li>
        </ul>
        <div className="ticket-list">
          {dataList[0] === "loading" ? (
            <AllTicketLoader />
          ) : dataList.length === 0 ? (
            <div className="alert"> {t("profil.history_empty")}</div>
          ) : (
            dataList.map((data) => (
              <div
                className="ticket-item"
                key={
                  type === "conferences" ? data.conference_id : data.course_id
                }
              >
                <div
                  className="info-area"
                  onClick={() =>
                    navigate(
                      `/general-detail/${
                        type === "courses" ? "courses" : "conferences"
                      }/${
                        type === "courses" ? data.course_id : data.conference_id
                      }`
                    )
                  }
                >
                  <div className="icon">
                    <img src={logo} alt="" />
                  </div>
                  <p className="action-name">
                    {type === "conferences"
                      ? data.conferencename
                      : data.coursename}
                  </p>
                </div>
                <button
                  className="watch-ticket"
                  onClick={() => {
                    setSelectAction(data);
                    console.log(data);
                    setTicketVisible(true);
                  }}
                >
                  {t("banner_detail.ticket_watch")}{" "}
                  <img src={pdf} alt="document" />
                </button>
              </div>
            ))
          )}
        </div>
      </section>
      {ticketVisible && (
        <Ticket
          data={{
            participant: {
              signcode: selectAction.signcode,
            },
          }}
          participationType={selectAction.type.type}
          type={type}
          eventStartDate={
            type === "conferences"
              ? selectAction.conferencestartdate
              : selectAction.coursestartdate
          }
          eventLocation={
            type === "conferences" ? selectAction.conferencelocation : ""
          }
          eventPrice={selectAction.type}
          eventHost={selectAction.partner.name}
          eventPhone={selectAction.partner.phone}
          eventMail={selectAction.partner.email}
          eventName={
            type === "conferences"
              ? selectAction.conferencename
              : selectAction.coursename
          }
        />
      )}
    </>
  );
};

export default AllTicket;
